import * as React from "react";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import { Toaster, toast } from "react-hot-toast";
import { SSRProvider } from "@react-aria/ssr";
import Bugsnag from "@bugsnag/js";
import Head from "next/head";
import BugsnagPluginReact, {
  BugsnagErrorBoundary as BugsnagErrorBoundaryAPI,
} from "@bugsnag/plugin-react";
import {
  fetchProductOffers,
  IFetchProductOffersResponse,
  OfferProvider,
} from "@nju/data-access-portal-offers";
import { maybeRefreshTokens, UserProvider } from "@nju/data-access-portal-user";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Chat } from "@nju/feature-portal-chat";
import { create } from "@nju/http-client";
import { IconProvider } from "@nju/icons";
import sprite from "@nju/icons/sprite.svg";
import { BasicError } from "@nju/result";
import { OverlayProvider } from "@nju/ui";
import cache from "memory-cache";
import { Debug, DebugProvider } from "@nju/debug";
import { getFeatureFlagValue, getFeatureValue } from "@nju/feature-flags";
import { Maintenance } from "@nju/feature-portal-technical";
import { ErrorToast } from "../components/ErrorToast";
import "../styles/index.css";
import { GlobalErrorBoundary } from "../components/GlobalErrorBoundary";
import { portalRoutes } from "@nju/routes";
import { DidomiSDK } from "@didomi/react";

create({
  hooks: {
    refreshToken: () => {
      return maybeRefreshTokens({ scope: "portal" });
    },
    onUnauthorized: () => {
      Router.push(portalRoutes.index.get());
    },
  },
  options: {
    timeout: getFeatureFlagValue("longTimeouts") === "1" ? 60_000 : 20_000,
  },
});

let BugsnagErrorBoundary: BugsnagErrorBoundaryAPI | undefined;
let isBugsnagEnabled = false;

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  const isDev = process.env.NODE_ENV === "development";

  if (!isBugsnagEnabled) {
    isBugsnagEnabled = true;
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: isDev ? "local" : process.env.NEXT_PUBLIC_APP_ENV,
      appVersion: "portal-" + process.env.NEXT_PUBLIC_APP_VERSION,
      enabledReleaseStages: ["dev", "int", "prd"],
      logger: isDev
        ? undefined
        : // eslint-disable-next-line unicorn/no-null
          null,
      onError: (event) => {
        if (event.originalError instanceof BasicError) {
          if (getFeatureFlagValue("errorToasts") === "1") {
            toast.error(
              (t) => {
                return <ErrorToast error={event.originalError} toast={t} />;
              },
              {
                position: "bottom-center",
                duration: 1000 * 20,
              }
            );
          }
          event.addMetadata("info", { ...event.originalError });
        }
      },
    });
  }
  BugsnagErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

interface OffersSwitcherProps {
  offers: IFetchProductOffersResponse;
  children: ({
    offers,
  }: {
    offers: IFetchProductOffersResponse;
  }) => React.ReactNode;
}

function OffersSwitcher(props: OffersSwitcherProps) {
  const [isReady, setIsReady] = React.useState(false);
  const [offers, setOffers] = React.useState<IFetchProductOffersResponse>(
    props.offers
  );

  React.useEffect(() => {
    const offerIdFromFlags = getFeatureValue("offerId");

    const firstOffer = props.offers.mobile.indefiniteOffers.filter((offer) => {
      return offer.offerId === offerIdFromFlags;
    });

    const restOffers = props.offers.mobile.indefiniteOffers.filter((offer) => {
      return offer.offerId !== offerIdFromFlags;
    });

    setOffers({
      ...props.offers,
      mobile: {
        indefiniteOffers: offerIdFromFlags
          ? [...firstOffer, ...restOffers]
          : props.offers.mobile.indefiniteOffers,
      },
    });
    setIsReady(true);
  }, [props.offers]);

  if (process.env.NEXT_PUBLIC_APP_ENV === "prd") {
    return <>{props.children({ offers })}</>;
  }

  if (isReady) {
    return <>{props.children({ offers })}</>;
  }

  // eslint-disable-next-line unicorn/no-null
  return null;
}

export default function App(
  props: AppProps & { offers: IFetchProductOffersResponse; error?: boolean }
) {
  const router = useRouter();
  const [hrefToCanonical, setHrefToCanonical] = React.useState("");

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const lastValuePathname = pathname[pathname?.length - 1];

    if (pathname === "/") {
      setHrefToCanonical("https://nju.pl");
    } else {
      if (lastValuePathname === "/" && pathname?.length > 1) {
        const slicePathname: string = pathname?.slice(0, pathname?.length - 1);
        setHrefToCanonical(`https://nju.pl${slicePathname}`);
      } else {
        setHrefToCanonical(`https://nju.pl${pathname}`);
      }
    }
  }, [router.asPath]);

  const didomiConfig = {
    app: {
      user: {
        externalConsent: {
          enabled: true,
        },
      },
    },
  };

  return (
    <SSRProvider>
      <Head>
        {hrefToCanonical && <link href={hrefToCanonical} rel="canonical" />}
      </Head>
      <DidomiSDK
        apiKey={process.env.NEXT_PUBLIC_DIDOMI_API_KEY}
        config={didomiConfig}
        embedTCFStub={true}
        gdprAppliesGlobally={true}
        iabVersion={2}
        noticeId={process.env.NEXT_PUBLIC_DIDOMI_NOTICE_ID}
        sdkPath="https://sdk.privacy-center.org/"
      />
      <DebugProvider>
        <DefaultSeo
          description="Wybierz nju światłowód i nju subskrypcja, by stworzyć pakiet, dzięki któremu obniżysz cenę. W pakiecie taniej, dołącz do miliona grona klientów nju mobile i ciesz się nowoczesnymi usługami bez papierowych umów i faktur."
          title="nju subskrypcja i nju światłowód | nju.pl"
        />
        {!props.error && (
          <OffersSwitcher offers={props.offers}>
            {({ offers }) => {
              return (
                <OfferProvider offers={offers}>
                  <OverlayProvider>
                    <UserProvider>
                      <IconProvider spritePath={sprite}>
                        <ReactErrorBoundary
                          FallbackComponent={GlobalErrorBoundary}
                        >
                          <Maintenance scope="portal">
                            <Chat />
                            {BugsnagErrorBoundary ? (
                              <BugsnagErrorBoundary
                                FallbackComponent={GlobalErrorBoundary}
                              >
                                <props.Component {...props.pageProps} />
                                <Toaster />
                              </BugsnagErrorBoundary>
                            ) : (
                              <props.Component {...props.pageProps} />
                            )}
                            <Debug />
                          </Maintenance>
                        </ReactErrorBoundary>
                      </IconProvider>
                    </UserProvider>
                  </OverlayProvider>
                </OfferProvider>
              );
            }}
          </OffersSwitcher>
        )}
      </DebugProvider>
    </SSRProvider>
  );
}

App.getInitialProps = async () => {
  let result: IFetchProductOffersResponse | null = cache.get("productOffers");

  if (!cache.get("productOffers")) {
    try {
      result = (await fetchProductOffers()).unwrap();
      cache.put("productOffers", result, 300_000); // 5 min
    } catch (error) {
      if (typeof window === "undefined") {
        throw error;
      } else {
        window.location.href = portalRoutes.ups.get();
      }
      return {
        error: true,
        offers: [],
      };
    }
  }

  return {
    error: false,
    offers: result,
  };
};
