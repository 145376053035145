import * as React from "react";
import { A, Separator, Text } from "@nju/ui";
import Link from "next/link";
import { Icon } from "@nju/icons";

export function Documents() {
  return (
    <div className="flex gap-x-[184px] gap-y-8 flex-col from-desktop:flex-row">
      <div data-cy="footer-first-column">
        <Link
          aria-label="kontakt"
          href="https://www.njumobile.pl/obsluga/kontakt"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            kontakt
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="roaming"
          href="https://www.njumobile.pl/roaming"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            roaming
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="warunki zakupów"
          href="https://www.njumobile.pl/warunki-zakupow"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            warunki zakupów
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="dbajmy razem o planetę"
          href="https://www.njumobile.pl/eko"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            dbajmy razem o planetę
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="ustawienia plików cookies"
          href="javascript:Didomi.preferences.show()"
          passHref={true}
        >
          <A display="inline" size="inline" variant="neutral">
            <Text size="s" withUnderline={true}>
              ustawienia plików cookies
            </Text>
          </A>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="polityka prywatności aplikacja"
          href="https://www.njumobile.pl/b/articles/inne/polityka-prywatnosci-nju-swiatlowod.pdf"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            polityka prywatności aplikacja
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="mapa serwisu"
          href="https://www.njumobile.pl/mapa-serwisu"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            mapa serwisu
          </Text>
        </Link>
      </div>
      <div data-cy="footer-second-column">
        <Link
          aria-label="regulaminy i cenniki"
          href="https://www.njumobile.pl/regulaminy-i-cenniki"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            regulaminy i cenniki
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="polityka prywatności"
          href="https://www.njumobile.pl/b/articles/inne/polityka-prywatnosci-nju.pdf"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            polityka prywatności
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="ochrona danych osobowych"
          href="https://www.njumobile.pl/mojedane"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            ochrona danych osobowych
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="artykuły"
          href="https://www.njumobile.pl/artykuly"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            artykuły
          </Text>
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="oferta w języku ukraińskim"
          href="https://cms-doptools-pl.s3.eu-central-1.amazonaws.com/ulotka_ua_023e7ca984.pdf"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            пропозиція укр
          </Text>
          <Icon
            className="relative bottom-[3px] pl-1"
            name="flagUA"
            width={24}
          />
        </Link>
        <Separator size="none" tone="invisible" />
        <Link
          aria-label="oferta w języku angielskim"
          href="https://cms-doptools-pl.s3.eu-central-1.amazonaws.com/ulotka_eng_9e706dba2c.pdf"
          rel="noreferrer"
        >
          <Text size="s" withUnderline={true}>
            offer in English
          </Text>
          <Icon
            className="relative bottom-[3px] pl-1"
            name="flagUK"
            width={24}
          />
        </Link>
      </div>
    </div>
  );
}
